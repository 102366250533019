<template>
  <div class="main-wrap">
    <div class="caseHomebanner">
      <div class="bannercontent">
        <div class="subnav">
          <div class="top">
            <img v-if="this.ParFamilyInfo.profilePhotoPath==1" src="../../assets/images/m1.png" alt="">
            <img v-if="this.ParFamilyInfo.profilePhotoPath==2" src="../../assets/images/m2.png" alt="">
            <img v-if="this.ParFamilyInfo.profilePhotoPath==3" src="../../assets/images/m3.png" alt="">
            <img v-if="this.ParFamilyInfo.profilePhotoPath==4" src="../../assets/images/m4.png" alt="">
            <img v-if="this.ParFamilyInfo.profilePhotoPath==5" src="../../assets/images/m5.png" alt="">
          </div>
          <div class="submenu">
            <h2>孩子：{{ParChildren.stuName}}</h2>
            <div class="inf">
              <p>{{ParChildren.proviceName}}{{ParChildren.cityName}}</p>
              <p>{{ParChildren.schoolName}}</p>
              <p>{{ParChildren.gradeName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="caTm">
        <div class="caTop">
          <div class="lf">
            <img src="../../assets/images/case_icon01.png" alt="">
            <h2>学生端统计信息</h2>
          </div>
        </div>
        <div class="Slist">
          <li class="Sbooks cursor" @click="toParHomeWorkComplete()">
            <div class="bj01">
              <img src="../../assets/images/case_06.png" alt="">
            </div>
            <div class="pName">作业/考试完成情况</div>
          </li>
          <li class="Sbooks cursor" @click="toParHomeaChievComplete()">
            <div class="bj02">
              <img src="../../assets/images/case_07.png" alt="">
            </div>
            <div class="pName">成绩统计情况</div>
          </li>
          <li class="Sbooks cursor" @click="toKnowledgeMastery()">
            <div class="bj03">
              <img src="../../assets/images/case_08.png" alt="">
            </div>
            <div class="pName">知识内容测评</div>
          </li>
          <li class="Sbooks cursor" @click="toPersonalGrowth()">
            <div class="bj04">
              <img src="../../assets/images/case_09.png" alt="">
            </div>
            <div class="pName">个人成长轨迹</div>
          </li>
          <li class="Sbooks cursor" @click="toOrderQuery()">
            <div class="bj05">
              <img src="../../assets/images/case_10.png" alt="">
            </div>
            <div class="pName">订单查询</div>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {getParFamilyInfoObj,getParChildrenObj} from '@/api/parent'
import {findScreenSubjectObj} from '@/api/caseHome'
export default {
  name: '',
  inject: ['reload'],
  data() {
    return {
      picImgSrc: {},
      portraitList: [
        {id:1, url: require('../../assets/images/m1.png')},
        {id:2, url: require('../../assets/images/m2.png')},
        {id:3, url: require('../../assets/images/m3.png')},
        {id:4, url: require('../../assets/images/m4.png')},
        {id:5, url: require('../../assets/images/m5.png')},
      ],
      EducationCase:'',
      par:'',

      paruserInfo:{},
      subjectId:null,
      clickFlag:null,
      clickFlagB:null,
      BoutiqueXlaData:[],

      cur: 0,//默认选中第一个tab
    }
  },
  computed:{
    ...mapState([
      'ParFamilyInfo',
      'ParChildren',
    ]),

  },
  created() {
    this.portraitList.forEach((ele) => {
      if (this.ParFamilyInfo.profilePhotoPath == ele.id){
        this.picImgSrc = ele
      }
    })
    this.paruserInfo = JSON.parse(localStorage.getItem("paruserInfo"));
    this.EducationCase = this.$route.query.EducationCase;
    this.studentMobileNumber = this.paruserInfo.studentMobileNumber
    this.par = this.$route.query.par;
    this.getParFamilyInfo()
    this.getParChildren()
    this.findScreenSubject()


  },
  methods: {
    // 查询家长信息  paruserInfo
    getParFamilyInfo() {
      let obj = {
        userName:this.paruserInfo.userName
      }
      getParFamilyInfoObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          this.StuByUser = res.data
          this.ParFamilyInfo=res.data
          console.log(this.StuByUser,'查询家长信息')
          this.$store.commit('saveParFamilyInfo', res.data)

        }
      })
    },
    // 查询家长信息  paruserInfo
    getParChildren() {
      let obj = {
        studentMobileNumber:this.paruserInfo.studentMobileNumber
      }
      getParChildrenObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveParChildren', res.data)
        }
      })
    },
    // 查询筛选的学科
    findScreenSubject() {
      findScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveSubjectList', res.data)
        }
      })
    },
    // 跳转到作业考试完成情况
    toParHomeWorkComplete() {
      this.$router.push({
        path: "/mainb/parHomeWorkComplete",
        query: {
          EducationCase:2,
          clickFlag:0,
          par:1,
        }
      });
    },
    // 跳转到成绩统计情况
    toParHomeaChievComplete() {
      this.$router.push({
        path: "/mainb/parHomeaChievComplete",
        query: {
          EducationCase:2,
          clickFlag:1,
          par:1,
        }
      });
    },
    // 跳转到知识点掌握情况
    toKnowledgeMastery() {
      this.$router.push({
        path: "/mainb/KnowledgeMastery",
        query: {
          EducationCase:2,
          clickFlag:2,
          par:1,
        }
      });
    },
    // 跳转到个人成长轨迹
    toPersonalGrowth() {
      this.$router.push({
        path: "/mainb/personalGrowth",
        query: {
          EducationCase:2,
          clickFlag:3,
          par:1,
        }
      });
    },
    // 跳转到订单
    toOrderQuery() {
      this.$router.push({
        path: "/mainb/orderQuery",
        query: {
          EducationCase:2,
          clickFlag:4,
          orderCur:0,
          par:1,
        }
      });
    },



    //点击改变文字颜色
    onClickItemA(item){
      this.clickFlag = item
      this.listQueryBoutiqueXla(item)
    },


    onClickItemB(item){
      this.clickFlagB = item;
      this.listQueryCommUseXla(item)
    },




  },

  components: {
    // 图标


    //注册图标组件名称
  },
  watch: {
    'ParFamilyInfo' : {
      handler:function (newVal) {
        this.ParFamilyInfo=newVal

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrap {
  background: #fff;
  .caseHomebanner {
    width: 100%;
    height: 500px;
    background: url("../../assets/images/parentBanner.png") center center no-repeat;
    background-size: cover;

    .bannercontent {
      width: 1200px;
      height: 500px;
      margin: auto;

      .subnav {
        width: 500px;
        height: 240px;
        border-radius: 15px;
        background-color: #CBEEFF;
        position: relative;
        margin: 90px auto auto 700px;


        .top {
          width: 54px;
          height: 54px;
          padding: 3px;
          position: absolute;
          background-color: #9CDFFF;
          border-radius: 50px;
          font-size:26px ;
          font-weight: bold;
          top:-20px;
          margin:auto auto auto 220px;
          img {
            width: 54px;
            height: 54px;
          }
        }
        .submenu {
          width: 100%;
          font-size:14px ;
          h2 {
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            padding-top: 60px;
            padding-bottom: 20px;
            margin: auto;
          }
          .inf {
            width: 100%;
            text-align: center;
            p {
              line-height: 30px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
    margin: 0px 0px 20px 0px;
    justify-content: space-between;
    width: 1300px;
    border-radius: 0px;
    .caTm {
      margin-top: 10px;
      .caTop {
        width: 100%;
        display: flex;
        .lf{
          width: 500px;
          display: inline-flex;
          img{height:22px;margin-right: 10px; margin-top: 8px}
          h2 {line-height:40px;border-bottom:2px solid #AF69F3;font-weight:bold;color:#AF69F3;font-size: 16px}
        }
        .rg{
          width: 800px;
          .subject-box {
            width: 800px;
            line-height: 40px;
            text-align: right;
            .sname {
              text-align: right;
              padding: 4px 10px;
              font-size: 14px;
            }
            .onClickStyle {
              color: #FF6820;
              background: #FFEFE8;
              border: 1px solid #FF6820;
              opacity: 1;
              border-radius: 15px;
            }
          }
        }
      }
      .Slist {
        margin-top: 24px;
        display: flex;
        .Sbooks {
          width: 231px;
          height: 241px;

          float: left;
          text-align: center;
          margin-right: 26px;
          padding-top: 10px;
          box-sizing: border-box;
          .bj01 {
            width: 100%;
            height: 200px;
            background: #3FE6FF url("../../assets/images/p_case_06.png") no-repeat 50px 30px;
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .bj02 {
            width: 100%;
            height: 200px;
            background: linear-gradient(120deg, #FFA86A 0%, #FEA466 3%, #EE611C 69%, #E94700 100%);
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .bj03 {
            width: 100%;
            height: 200px;
            background: linear-gradient(120deg, #FFDA3F 0%, #FFAA17 59%, #FF8E00 100%);
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .bj04 {
            width: 100%;
            height: 200px;
            background: linear-gradient(120deg, #2AF6FF 0%, #0EBEC6 63%, #00A2AA 100%);
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .bj05 {
            width: 100%;
            height: 200px;
            background: linear-gradient(120deg, #3FF1FF 1%, #229EEB 42%, #0953DA 82%, #0037D4 100%);
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .bj06 {
            width: 100%;
            height: 200px;
            background: #F85366 url("../../assets/images/p_case_11.png") no-repeat 50px 30px;
            opacity: 1;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            justify-items: center;
            img {
              width: 54px;
              height: 54px;
            }
          }
          .pName {
            font-size: 14px;
            text-align: center;
            line-height: 40px;
          }
          img {
            width: 110px;
            height: 160px;
            margin: auto;
          }
          .bookinf {
            width: 100%;
            display: flex;
            padding-top: 8px;
            .bklf {
              width: 80px;
              margin-right: 20px;
              p {line-height: 18px;color: #fff}
              h2 {margin-top:5px;font-weight: bold;font-size: 18px;color: #FF5757}
            }
            .bkrg {
              width: 150px;
              padding-top: 18px;
              padding-right: 10px;
              line-height: 18px;
              box-sizing: border-box;
              font-size: 14px;
              color: #fff; text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
            }
          }
        }


        .Sbooks:nth-child(5n+5) {
          border-right: 0;
          margin-right: 27px;
          margin-bottom: 20px;
        }

        .Sbooks:nth-child(n+6) {
          margin-right: 0px;
          border-bottom: 0
        }
        //
        //.Sbooks:hover {
        //  box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
        //}
      }
    }
    .cYTm {
      margin-top: 10px;
      .caTop {
        width: 100%;
        display: flex;
        .lf{
          width: 500px;
          display: inline-flex;
          img{height:22px;margin-right: 10px; margin-top: 8px}
          h2 {line-height:40px;border-bottom:2px solid #FF6820;font-weight:bold;color:#FF6820;font-size: 16px}
        }
        .rg{
          width: 800px;
          .subject-box {
            width: 800px;
            line-height: 40px;
            text-align: right;
            .sname {
              text-align: right;
              padding: 4px 10px;
              font-size: 14px;
            }
            .onClickStyle {
              color: #FF6820;
              background: #FFEFE8;
              border: 1px solid #FF6820;
              opacity: 1;
              border-radius: 15px;
            }
          }
        }
      }
      .Slist {
        margin-top: 24px;
        display: flex;
        .Sbooks {
          width: 231px;
          height: 241px;
          background: url("../../assets/images/baj.png") no-repeat;
          float: left;
          text-align: center;
          margin-right: 26px;
          padding-top: 10px;
          box-sizing: border-box;
          img {
            height: 160px;
            margin: auto;
          }
          .bookinf {
            width: 100%;
            display: flex;
            padding-top: 8px;
            .bklf {
              width: 80px;
              margin-right: 20px;
              p {line-height: 18px;color: #fff}
              h2 {margin-top:5px;font-weight: bold;font-size: 18px;color: #FF5757}
            }
            .bkrg {
              width: 150px;
              padding-top: 18px;
              padding-right: 10px;
              line-height: 18px;
              box-sizing: border-box;
              font-size: 14px;
              color: #fff; text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;
            }
          }
        }

        .Sbooks:nth-child(4n+4) {
          border-right: 0;
          margin-right: 27px;
          margin-bottom: 20px;
        }

        .Sbooks:nth-child(n+5) {
          margin-right: 0px;
          border-bottom: 0
        }
        //
        //.Sbooks:hover {
        //  box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
        //}
      }
    }


    .logo-wrap {
      width: 640px;
      position: relative;
      margin-top: 80px;

      img {
        width: 300px;
        float: right;
        margin-top: 50px;
      }
    }

    .centreContent {
      width: 520px;
      padding: 40px 40px;
      box-sizing: border-box;
      /*height: 540px;*/
      float: right;
      border-radius: 20px;
      margin-right: -100px;

      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      opacity: 0.9;

      .tabs {
        width: 100%;
        padding: 0;
        text-align: center;

        .active1 {
          border-radius: 50px;
          border: 1px solid #F77134;
          background: #FFFCB2;
          color: #ff6820;
        }

        .li-tab {
          width: 60px;
          height: 60px;
          border-radius: 50px;
          border: 1px solid #bbb;
          margin: 0px 25px 0 25px;
          display: inline-block;
          text-align: center;
          font-size: 14px;
          cursor: pointer;

          img {
            width: 60px;
            height: 60px;
          }

          .t1 {
            text-align: center;
          }
        }

        .divTab {
          margin-top: 30px;

          ::v-deep .el-input__inner {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            padding: 0 54px;
          }

          .form-item {
            position: relative;

            .user {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .lock {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .ScaleToOriginal {
              color: #666;
              font-size: 24px;
              position: absolute;
              z-index: 9;
              margin-top: 10px;
              margin-left: 10px;
              padding-right: 10px;
              border-right: 1px solid #d5dee9;
            }

            .code-img {
              width: 84px;
              height: 40px;
              position: absolute;
              top: 0px;
              right: 0;
              cursor: pointer
            }
          }

        }
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -130px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 50px;
    z-index: 99;

    .cop1 {
      height: 30px;
      line-height: 30px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

}
</style>
